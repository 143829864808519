// extracted by mini-css-extract-plugin
export var active = "BlogPage-module--active--da4bb";
export var blogAndSearchWrapper = "BlogPage-module--blogAndSearchWrapper--06d83";
export var blogCardAndPaginate = "BlogPage-module--blogCardAndPaginate--04422";
export var blogCardWrapper = "BlogPage-module--blogCardWrapper--0689b";
export var blogHeading = "BlogPage-module--blogHeading--41787";
export var blogWrapper = "BlogPage-module--blogWrapper--c7efd";
export var disabled_page = "BlogPage-module--disabled_page--d0df3";
export var inputSearch = "BlogPage-module--inputSearch--a257d";
export var item = "BlogPage-module--item--31207";
export var next = "BlogPage-module--next--1a09a";
export var nextText = "BlogPage-module--nextText--1c046";
export var notFoundText = "BlogPage-module--notFoundText--5bf8d";
export var pagination = "BlogPage-module--pagination--e9135";
export var pagination_page = "BlogPage-module--pagination_page--e5b50";
export var prevText = "BlogPage-module--prevText--18bdb";
export var previous = "BlogPage-module--previous--9d445";
export var searchWrapper = "BlogPage-module--searchWrapper--5f256";
export var tabAndSearch = "BlogPage-module--tabAndSearch--25689";
export var tabWrapper = "BlogPage-module--tabWrapper--5ca91";