import React from "react";
import { Typography, Box, Divider, Button } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";

// Schemas
import { signUpSchema } from "../../schemas";

// Data
import cardContent from "../../Data/BlogData";

// styles
import * as styles from "../../styles/BlogPage/PopularBlogs.module.scss";

// Icons
import CircleIcon from "@mui/icons-material/Circle";

// images
import NewsletterImage from "../../images/newsletterImageBlog.svg";

const initialValues = {
  email: "",
};

const PopularBlogs = () => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    status,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      axios
        .post(`https://st-backend-quickxi.invo.zone/api/messages`, {
          data: {
            Email: values?.email,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            action.setStatus({ success: "Email sent !" });
            setTimeout(() => {
              action.resetForm();
            }, 1000);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  return (
    <>
      <Box className={styles.popularBlogs}>
        <Box className={styles.popularBlogsHeading}>
          <Typography paragraph={true}>Most Popular Blogs</Typography>
        </Box>
        {cardContent.slice(0, 5).map((content, index) => {
          return (
            <Box className={styles.blogList} key={index}>
              <Box className={styles.blogListContent}>
                <img src={content.image} alt="blogs" />
                <Typography paragraph={true}>{content.title}</Typography>
              </Box>
              <Box className={styles.blogListAuthor}>
                <Typography paragraph={true}>Olivia Rhye</Typography>
                <CircleIcon
                  sx={{
                    fill: `#30303080`,
                    fontSize: "5px",
                    opacity: "0.5",
                  }}
                />
                {content.categories.slice(0, 1).map((name) => {
                  return <Typography paragraph={true}>{name}</Typography>;
                })}
                <CircleIcon
                  sx={{
                    fill: `#30303080`,
                    fontSize: "5px",
                    opacity: "0.5",
                  }}
                />
                <Typography paragraph={true}>20 Jan 2022</Typography>
              </Box>
              {index === cardContent.slice(0, 5).length - 1 ? (
                ""
              ) : (
                <Divider
                  sx={{
                    fill: `#D9D9D9`,
                    minWidth: "100%",
                    maxHeight: "2px",
                    margin: "15px 0px",
                  }}
                />
              )}
            </Box>
          );
        })}
        <Box className={styles.blogNewsletter}>
          <img src={NewsletterImage} alt="newsletter" />
          <Typography paragraph={true}>
            Get Quickxi latest news updates and exclusive offers delivered
            directly to your inbox. DON'T MISS OUT!
          </Typography>
          <Box className={styles.newsLetterForm}>
            <form>
              <Box className={styles.newsLetterInput}>
                <input
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  className="message_Error"
                  sx={{
                    color: "#ba0909 !important",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  {errors.email && touched.email ? errors.email : null}
                </Typography>
              </Box>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting ? true : false}
              >
                Subscribe
              </Button>
              <Box className="formMessages">
                <Typography
                  className="message_Success"
                  sx={{
                    color: "#24D18E",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  {status ? status.success : ""}
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PopularBlogs;
