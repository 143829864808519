import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import Layout from "../Components/Common/Layout";
import { Container, Typography, Box } from "@mui/material";
import BlogCard from "../Components/Common/BlogCard";
import ReactPaginate from "react-paginate";

// Icons
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Styles
import * as styles from "../styles/BlogPage/BlogPage.module.scss";

// Data
import cardContent from "../Data/BlogData";
import PopularBlogs from "../Components/BlogPage/PopularBlogs";

const Blog = () => {
  const [value, setValue] = useState(0);
  const [items, setItems] = useState(cardContent || []);

  let itemsPerPage = 4;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  let updatedList = "";
  const filterBySearch = (event) => {
    const query = event.target.value;
    if (query.toString() === "") {
      return setItems(cardContent || []);
    }
    if (query) {
      updatedList = items.filter((value) => {
        return value.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } else {
      updatedList = items;
    }
    setItems(updatedList);
    setItemOffset(0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentItems]);

  const categories = [
    "All",
    "Marketing",
    "Software",
    "Customer Service",
    "E-Commerce",
    "Restaurant",
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Container>
        <Box className={styles.blogWrapper}>
          <Box className={styles.blogHeading}>
            <Typography variant="h1">Our Blogs</Typography>
            <Typography paragraph={true}>
              Check our resources and be a part of our tech-savvy community! We
              write about revolutionary eCommerce practices & creative web/app
              template builder.
            </Typography>
          </Box>
          <Box className={styles.tabAndSearch}>
            {/* Tabs */}
            <Box className={styles.tabWrapper}>
              <Tabs
                variant={categories.length > 6 ? "scrollable" : ""}
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#606060" } }}
              >
                {categories.map((tab, index) => (
                  <Tab key={index} label={tab} />
                ))}
              </Tabs>
            </Box>
            {/* Search */}
            <Box className={styles.searchWrapper}>
              <Box className={styles.inputSearch}>
                <SearchIcon
                  sx={{
                    fill: "#30303080",
                  }}
                />
                <input
                  type="text"
                  placeholder="Search Topics"
                  onChange={filterBySearch}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.blogAndSearchWrapper}>
            <Box className={styles.blogCardAndPaginate}>
              {items.length > 1 ? (
                <Box className={styles.blogCardWrapper}>
                  {currentItems?.map((card, index) => {
                    return (
                      <BlogCard
                        text={card.title}
                        para={card.para}
                        image={card.image}
                        background={card.backColor}
                        hoverColor={card.hoverColor}
                        categories={card.categories}
                        categoryBack={card.categoryBack}
                        key={index}
                      />
                    );
                    // return <BlogCard blogData={blogData} key={index} />;
                  })}
                </Box>
              ) : (
                <Box className={styles.notFoundText}>
                  <Typography paragraph={true}>No Such Blog Found</Typography>
                </Box>
              )}
              <ReactPaginate
                activeClassName={`${styles.item} ${styles.active}`}
                breakClassName={`${styles.item} break-me`}
                containerClassName={`${styles.pagination}`}
                disabledClassName={`${styles.disabled_page}`}
                marginPagesDisplayed={2}
                nextClassName={`${styles.item} ${styles.next}`}
                pageClassName={`${styles.item} ${styles.pagination_page}`}
                previousClassName={`${styles.item} ${styles.previous}`}
                nextLabel={
                  <>
                    <span className={styles.nextText}>Next</span>
                    <ArrowForwardIcon style={{ fontSize: 18 }} />
                  </>
                }
                previousLabel={
                  <>
                    <ArrowBackIcon style={{ fontSize: 18 }} />
                    <span className={styles.prevText}>Previous</span>
                  </>
                }
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
              />
            </Box>
            <PopularBlogs />
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default Blog;
